import { generatePath } from 'react-router-dom';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { globalLocations } from 'src/pages/locations';
import { DeliveryMethodOrigin } from 'src/utils/consts';
import { NavigateType } from 'src/utils/types';
import { onboardingLocations } from '../../locations';
import { Origin } from './types';

export const getPrefix = (origin: Origin) =>
  origin === DeliveryMethodOrigin.MSN_PORTAL_GENERIC_LINK_CREATION ? 'MsnPortal' : '';

export const createLabel = ({ subject, prefix, suffix }: { subject: string; prefix: string; suffix: string }) =>
  `onboarding.vendorCompanyInfo.${subject}${prefix}.${suffix}`;

export const handleLegalInfoNextStep = async ({ origin, navigate }: { origin: Origin; navigate: NavigateType }) => {
  if (origin === DeliveryMethodOrigin.MSN_PORTAL_GENERIC_LINK_CREATION) {
    navigate(onboardingLocations.vendorCompanyInfo.added, false, { origin });
  } else {
    navigate(onboardingLocations.vendorCompanyInfo.businessType, false, { origin });
  }
};

export const handleLegalInfoPrevStep = ({ origin, navigate }: { origin: Origin; navigate: NavigateType }) =>
  navigate(onboardingLocations.vendorCompanyInfo.logo, false, { origin });

export const goToAddReceivingMethod = ({
  origin = '',
  navigate,
  orgId,
  ownedVendorId,
  isVendorAbsorbedFeeSet,
}: {
  origin?: Origin;
  navigate: NavigateType;
  orgId: number;
  ownedVendorId: null | number;
  isVendorAbsorbedFeeSet: boolean;
}) =>
  navigate(
    generatePath(globalLocations.receivingMethod.ach.addFromGetPaidOnboardingWrapper.base, {
      orgId,
      vendorId: ownedVendorId ?? undefined,
    }),
    false,
    {
      preservedState: {
        origin: origin || DeliveryMethodOrigin.ONBOARDING,
      },
      redirectUrl: isVendorAbsorbedFeeSet
        ? onboardingLocations.vendorCompanyInfo.added
        : onboardingLocations.vendorCompanyInfo.absorbFees,
      exitUrl: generatePath(getPaidLocations.dashboard, { orgId }),
    }
  );
