import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useOrgId } from 'src/hooks';
import { useAccountingFirmSkipsStep } from 'src/hooks/accountants/useAccountingFirmSkipsStep';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { profileStore } from 'src/modules/profile/profile-store';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { isCompanyLegalInfoDone } from 'src/pages/settings/records';
import { setCompanyInfoAction } from 'src/redux/user/actions';
import { getCompanyInfo, getOwnedVendorHandle, getOwnedVendorId } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications/notificationService';
import { DeliveryMethodOrigin, NotificationVariant } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { findOrCreateOwnedVendorWithHandle } from './utils';

export const useStartVendorCompany = ({ onBlockCreateLink }: { onBlockCreateLink: () => void }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const orgId = useOrgId();
  const isFirmSkipsFirstSteps = useAccountingFirmSkipsStep();
  const ownedVendorHandle = useSelector(getOwnedVendorHandle);
  const companyInfo = useSelector(getCompanyInfo);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const canCreateLink = useSelector(profileStore.selectors.getPermissions)?.requests?.createMelioLink();
  const [historyPush] = useHistoryWithOrgId();
  const createLinkUrl = onboardingLocations.vendorCompanyInfo.link;
  const { navigate } = useNavigator();
  const [origin] = useLocationState<DeliveryMethodOrigin>('origin');

  const startVendorOnboarding = async (locationState?: Record<string, unknown>) => {
    if (!canCreateLink) {
      onBlockCreateLink();

      return;
    }

    if (isFirmSkipsFirstSteps) {
      setIsLoading(true);

      try {
        const { handle, vendorId } = await findOrCreateOwnedVendorWithHandle(orgId, {
          ownedVendorHandle,
          ownedVendorId,
        });

        dispatch(
          setCompanyInfoAction({
            ...companyInfo,
            ownedVendorId: vendorId || null,
            ownedVendorHandle: handle,
          })
        );

        setIsLoading(false);

        if (isCompanyLegalInfoDone(companyInfo)) {
          navigate(generatePath(onboardingLocations.vendorCompanyInfo.businessType, { orgId }), false, {
            origin,
          });
        } else {
          navigate(generatePath(onboardingLocations.vendorCompanyInfo.legal, { orgId }), false, {
            origin,
          });
        }
      } catch (e) {
        setIsLoading(false);
        pushNotification({
          type: NotificationVariant.ERROR,
          msg: 'serverErrors.ERR',
        });
      }

      return;
    }

    historyPush({ path: createLinkUrl, state: locationState });
  };

  return { startVendorOnboarding, isLoading };
};
