import { useSelector } from 'react-redux';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { VendorCompanyContactNameForm } from './components/VendorCompanyContactNameForm';
import { useVendorCompanyInfoUpdates } from './hooks/useVendorCompanyInfoUpdates';

type Props = Parameters<typeof useVendorCompanyInfoUpdates>[0];

export const VendorCompanyContactNamePageContainer = ({
  companyInfoField,
  nextStepURL,
  prevStepURL,
  inputFields,
}: Props) => {
  const { onNext, onChange, onPrev, isLoading, validationErrors } = useVendorCompanyInfoUpdates({
    companyInfoField,
    nextStepURL,
    prevStepURL,
    inputFields,
  });

  const companyInfo = useSelector(getCompanyInfo);

  return (
    <VendorCompanyContactNameForm
      onNext={onNext}
      onPrev={onPrev}
      onChange={onChange}
      companyInfo={companyInfo}
      isLoading={isLoading}
      validationErrors={validationErrors}
    />
  );
};
