import { getValidationErrors, isValidationOk } from '@melio/sizzers-js-common';
import { Location } from 'history';
import every from 'lodash/every';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { PureComponent } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { AreaLoader } from 'src/components/common/AreaLoader';
import { organizationsApi } from 'src/modules/organizations/api';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import organizationStore from 'src/modules/organizations/organizations-store';
import { usersApi } from 'src/modules/users/api';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { settingsLocations } from 'src/pages/settings/locations';
import { companyInfoFactory } from 'src/pages/settings/records';
import { addressFactory } from 'src/pages/vendor/records';
import { GlobalState } from 'src/redux/types';
import { updateCompanyInfoAction } from 'src/redux/user/actions';
import { getCompanyInfo, getOrgId, getProfile } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import clientServiceApi from 'src/services/api/clientService';
import { breaks } from 'src/theme/appDevices';
import { convertToGoogleAddress, convertToServerAddress, whitePagesAddressKeys } from 'src/utils/address';
import { getMissedCompanyInfoFields } from 'src/utils/company';
import {
  ADDRESS_DEFAULTS,
  CompanyInfoOnboardingOrigin,
  CompanyType,
  DEFAULT_LOCATION,
  ManualAddress,
} from 'src/utils/consts';
import { isPOBox } from 'src/utils/delivery-methods';
import locations from 'src/utils/locations';
import { getEventNameFromLocation } from 'src/utils/string-utils';
import { AddressType, CompanyInfoType, FieldType, LegalAddressType, UserContextType } from 'src/utils/types';
import { getFullName } from 'src/utils/user';

type MapStateToProps = {
  profile: UserContextType;
  orgId: number;
  companyInfo: CompanyInfoType;
  organizationPreferences: Record<string, any>;
};

type MapDispatchToProps = {
  updateCompanyInfo: (companyInfo: CompanyInfoType, profile: UserContextType) => void;
};

type Props = {
  locationState: Record<string, any>;
  navigate: (url: string, shouldReplaceCurrent?: boolean, state?: Record<string, any> | null) => void;
  navigateWithPreservedState?: () => void;
  companyInfoField: string;
  nextStepURL: string;
  prevStepURL?: string;
  inputFields?: Array<string>;
  location: Location;
} & MapStateToProps &
  MapDispatchToProps & { intl: IntlShape };

const isAllCompanyDataFilled = (companyInfo) => {
  const requiredOrganizationFields = [
    'companyName',
    'contactFirstName',
    'contactLastName',
    'phone',
    'addressLine1',
    'city',
    'state',
    'zipCode',
  ];

  return every(requiredOrganizationFields, (field) => !isEmpty(companyInfo[field]));
};

export type CompanyInfoProps = {
  onPrev: (customCompanyInfo?: CompanyInfoType) => void;
  onNext: (customCompanyInfo?: CompanyInfoType, dataToUpdate?: Record<string, any>) => Promise<void>;
  goExit: () => void;
  onChange: (value: (string | null | undefined) | (number | null | undefined), fieldName?: string) => void;
  companyInfo: CompanyInfoType;
  isLoading: boolean;
  validationErrors: Record<string, any>;
  fieldsToShow: string[];
  navigateToCompanyOnboardingPage: (
    url: string,
    customCompanyInfo?: CompanyInfoType,
    overrideState?: Record<string, unknown>
  ) => void;
};

export type ManualAddressProps = {
  onPrev: (customCompanyInfo?: CompanyInfoType) => void;
  validationErrors: Record<string, any>;
  isLoading: boolean;
  manualAddress: AddressType | LegalAddressType;
  onManualAddressChange: (field: FieldType) => void;
  onAddressAddRequest: () => void;
  onAddressConfirmed: () => void;
  onManualAddressSelected: (arg0: string) => void;
  onEditAddress: () => void;
  manualAddressValidationErrors: Record<string, any>;
  validManualAddress: Record<string, any>;
  selectedAddressId: ManualAddress;
  confirmMode: boolean;
  invalidAddress: boolean;
};

type State = {
  selectedAddressId: string;
  companyInfo: CompanyInfoType;
  isLoading: boolean;
  ready: boolean;
  validationErrors: Record<string, any>;
  manualAddressValidationErrors: Record<string, any>;
  manualAddress: Record<string, any>;
  confirmMode: boolean;
  invalidAddress: boolean;
  validManualAddress: Record<string, any>;
  whitePagesKeys: Record<string, any>;
  fieldsToShow: string[];
};

const eventPage = 'onboarding-company-info';

const mapStateToProps = (state: GlobalState): MapStateToProps => ({
  profile: getProfile(state),
  orgId: getOrgId(state),
  companyInfo: getCompanyInfo(state),
  organizationPreferences: organizationStore.selectors.organizationPreferences(state),
});

const mapDispatchToProps = (dispatch): MapDispatchToProps => ({
  updateCompanyInfo(companyInfo: CompanyInfoType, profile: UserContextType) {
    updateCompanyInfoAction(dispatch, companyInfo, profile);
  },
});

export function withCompanyInfoUpdates() {
  return function (Component: any) {
    // eslint-disable-next-line max-len
    class ComponentWithCompanyInfoUpdates extends PureComponent<Props, State> {
      static defaultProps = {
        inputFields: [],
      };

      // eslint-disable-next-line react/sort-comp
      _isMounted = false;
      constructor(props: Props) {
        super(props);

        this.state = {
          selectedAddressId: ManualAddress.SUGGESTED,
          companyInfo: this.props.locationState
            ? companyInfoFactory(this.props.locationState.companyInfo)
            : companyInfoFactory(),
          isLoading: false,
          ready: false,
          validationErrors: {},
          manualAddressValidationErrors: {},
          manualAddress: addressFactory(),
          confirmMode: false,
          invalidAddress: false,
          whitePagesKeys: whitePagesAddressKeys,
          validManualAddress: {},
          fieldsToShow: [],
        };
      }

      componentDidMount() {
        this._isMounted = true;

        if (!this.state.companyInfo.companyName) {
          this.loadCompanyInfo();
        } else {
          const { companyInfoField } = this.props;

          if (companyInfoField === 'combined') {
            this.calculateCompanyFieldsToShow();
          } else {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({ ready: true });
          }
        }
      }

      componentWillUnmount() {
        this._isMounted = false;
      }

      calculateCompanyFieldsToShow = () => {
        const { companyInfo } = this.state;
        const fieldsToShow: string[] = getMissedCompanyInfoFields(companyInfo);

        if (fieldsToShow.length) {
          this.setState({ fieldsToShow, ready: true });
        } else {
          const companyType = companyInfo.companyType || CompanyType.SMB;
          const address = convertToGoogleAddress(companyInfo);
          const companyAddress = convertToServerAddress(address);
          const updatedCompanyInfo = { ...companyInfo, ...companyAddress, companyType };
          this.onNext(updatedCompanyInfo, updatedCompanyInfo);
        }
      };

      validateField = (fieldName: string, value: string, minLength: number, shouldValidateSpecialChar?: boolean) => {
        const { intl } = this.props;

        if (value && value.length < minLength) {
          return {
            [fieldName]: intl.formatMessage({ id: 'inputErrors.companyInfo.string.length' }, { min: minLength }),
          };
        }

        if (shouldValidateSpecialChar && !/^[a-zA-Z\s]*$/.test(value)) {
          return {
            [fieldName]: intl.formatMessage({ id: 'inputErrors.companyInfo.string.specialChar' }),
          };
        }

        return null;
      };

      performExtraValidations = (
        infoToUpdate: Record<string, any>,
        customCompanyInfo: Partial<CompanyInfoType> | undefined
      ) => {
        let validationErrors = {};

        const fieldsToValidate = {
          contactFirstName: { minLength: 2, shouldValidateSpecialChar: true },
          contactLastName: { minLength: 2, shouldValidateSpecialChar: true },
          companyName: { minLength: 3 },
        };

        Object.keys(fieldsToValidate).forEach((field) => {
          const fieldConfig = fieldsToValidate[field];
          const fieldValue = infoToUpdate?.[field];
          const error = this.validateField(
            field,
            fieldValue,
            fieldConfig.minLength,
            fieldConfig.shouldValidateSpecialChar
          );

          if (error) {
            validationErrors = { ...validationErrors, ...error };
          }
        });

        if (customCompanyInfo?.addressLine1 && isPOBox(customCompanyInfo?.addressLine1)) {
          validationErrors = {
            ...validationErrors,
            addressLine1: 'form.addressAutocomplete.error',
            error_type: 'PO_box',
          };
        }

        if (customCompanyInfo?.phone && customCompanyInfo?.phone.replace(/\D/g, '').length < 10) {
          validationErrors = {
            ...validationErrors,
            phone: 'inputErrors.companyInfo.phone.string.invalid',
          };
        }

        return validationErrors;
      };

      onNext = async (
        customCompanyInfo?: Partial<CompanyInfoType>,
        dataToUpdate?: Record<string, any>,
        userDataToUpdate?: Record<string, any>
      ) => {
        const {
          companyInfoField,
          profile,
          orgId,
          updateCompanyInfo,
          companyInfo,
          nextStepURL,
          organizationPreferences,
        } = this.props;
        const infoToUpdate = dataToUpdate || {
          [companyInfoField]: this.state.companyInfo[companyInfoField],
        };
        const origin = get(this.props.locationState, 'origin', '');
        const isAccountantAddCompanyFlow =
          origin === CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY || organizationPreferences.uninvitedOwnerEmail;
        const errorOrigin = isAccountantAddCompanyFlow && companyInfoField === 'contacts' ? 'companyOwnerInfo' : '';
        let validationErrors = getValidationErrors('companyInfo', infoToUpdate, this.props.inputFields, {
          errorOrigin,
        });

        validationErrors = {
          ...validationErrors,
          ...this.performExtraValidations(infoToUpdate, customCompanyInfo),
        };

        const eventName = `add-${companyInfoField}`;
        const isCompanyAddressPage = ['address', 'manual-address'].includes(companyInfoField);

        if (companyInfoField === 'phone' || companyInfoField === 'contactName' || companyInfoField === 'companyName') {
          analytics.setTraits(infoToUpdate);
        }

        if (companyInfoField === 'contacts') {
          const firstName = get(infoToUpdate, 'contactFirstName', '');
          const lastName = get(infoToUpdate, 'contactLastName', '');
          const phone = get(infoToUpdate, 'phone', '');
          const dateOfBirth = get(userDataToUpdate, 'dateOfBirth', '');
          analytics.setTraits({
            name: getFullName(firstName, lastName),
            contactFirstName: firstName,
            contactLastName: lastName,
            phone,
            dateOfBirth,
          });
        }

        if (isCompanyAddressPage) {
          analytics.setTraits({
            city: get(infoToUpdate, 'city', ''),
            zipCode: get(infoToUpdate, 'zipCode', ''),
            addressLine1: get(infoToUpdate, 'addressLine1', '').split(',')[0],
            state: get(infoToUpdate, 'state', ''),
          });

          analytics.triggerCampaignEvents('complete-company-profile-with-business-email');
        }

        analytics.track(eventPage, `${eventName}-continue`);
        this.setState({ validationErrors, isLoading: true }, async () => {
          if (isValidationOk(validationErrors)) {
            analytics.track(eventPage, `${eventName}-continue-success`);

            const isAllDataFilledBeforeUpdate = isAllCompanyDataFilled(companyInfo);
            const isAllDataFilledAfterUpdate = isAllCompanyDataFilled({ ...companyInfo, ...infoToUpdate });
            const confirmCompanyInfo = isAllDataFilledAfterUpdate && !isAllDataFilledBeforeUpdate;
            const newCompanyInfo = { ...infoToUpdate, confirmCompanyInfo };
            try {
              const { companyInfo: updatedCompanyInfo } = await organizationsApi.updateCompanyInfo(
                orgId,
                newCompanyInfo
              );

              if (userDataToUpdate) {
                await usersApi.update({ id: profile.id, ...userDataToUpdate });
              }

              const companyInfoRecord = companyInfoFactory({
                ...updatedCompanyInfo,
                logoUrl: companyInfo.logoUrl,
              });

              // check if user filled all necessary details
              const eligibleFields = ['contacts', 'contactName', 'companyName', 'address', 'manual-address', 'phone'];

              if (
                companyInfoRecord.companyName &&
                companyInfoRecord.contactFirstName &&
                companyInfoRecord.contactLastName &&
                companyInfoRecord.phone &&
                companyInfoRecord.zipCode &&
                eligibleFields.includes(companyInfoField)
              ) {
                const screenWidth = window?.parent?.innerWidth || 1024;

                if (!analytics.hasMqlEligibleEventCalled && screenWidth < breaks.phablet) {
                  analytics.track(eventPage, 'company-info-complete-in-mobile');
                }

                // also check it only on those pages to avoid calls
                analytics.trackMqlEligibleEvent(eventPage, 'mql-tag-eligible');
              }

              updateCompanyInfo(companyInfoRecord, profile);
              this.setState({ isLoading: false });

              if (companyInfoField === 'combined' && this.props.navigateWithPreservedState) {
                this.props.navigateWithPreservedState();
              } else if (isCompanyAddressPage && origin === CompanyInfoOnboardingOrigin.GUEST_PAYMENT_REQUEST) {
                this.navigateToCompanyOnboardingPage(onboardingLocations.companyInfo.added);
              } else if (
                isCompanyAddressPage &&
                this.props.navigateWithPreservedState &&
                origin === CompanyInfoOnboardingOrigin.SETTINGS
              ) {
                this.props.navigateWithPreservedState();
              } else {
                this.navigateToCompanyOnboardingPage(nextStepURL, customCompanyInfo);
              }
            } catch (error) {
              this.setState({ isLoading: false });
            }
          } else {
            analytics.track(eventPage, `${eventName}-validation-error`, validationErrors);
            this.setState({ isLoading: false });
          }
        });
      };

      navigateToCompanyOnboardingPage = (
        url: string,
        customCompanyInfo?: Partial<CompanyInfoType>,
        overrideState?: Record<string, unknown>
      ) => {
        this.props.navigate(url, false, {
          ...this.props.locationState,
          companyInfo: customCompanyInfo || this.state.companyInfo,
          ...overrideState,
        });
      };

      onManualAddressChange = ({ value, id }: FieldType) => {
        this.setState(({ manualAddress }) => ({
          manualAddress: {
            ...manualAddress,
            [id]: value,
          },
        }));
      };

      onPrev = (customCompanyInfo?: CompanyInfoType) => {
        const eventName = `add-${getEventNameFromLocation(this.props.location)}`;
        analytics.track(eventPage, `${eventName}-back`);
        const { prevStepURL } = this.props;

        if (this.props.locationState.origin === CompanyInfoOnboardingOrigin.SETTINGS) {
          this.props.navigate(settingsLocations.company);
        } else if (prevStepURL) {
          this.navigateToCompanyOnboardingPage(prevStepURL, customCompanyInfo);
        }
      };

      onChange = (value: (string | null | undefined) | (number | null | undefined), fieldName?: string) => {
        const fieldToUpdate = fieldName || this.props.companyInfoField;
        this.setState(({ companyInfo }) => ({
          companyInfo: { ...companyInfo, [fieldToUpdate]: value },
        }));
      };

      goExit = () => {
        const eventName = `add-${getEventNameFromLocation(this.props.location)}`;
        analytics.track(eventPage, `${eventName}-exit`);
        this.props.navigate(locations.MainApp.dashboard.url());
      };

      loadCompanyInfo = () => {
        this.setState({ isLoading: true });
        organizationsApi
          .getCompanyInfo(this.props.orgId)
          .then((fetchedCompanyInfo) => {
            if (this._isMounted) {
              const { companyInfoField } = this.props;
              const { localCompanyInfo: savedCompanyInfo, intuitCompanyInfo } = fetchedCompanyInfo || {};

              const assignFromProps = (field) => get(savedCompanyInfo, field) || get(intuitCompanyInfo, field);
              Object.keys(savedCompanyInfo).forEach((prop) => {
                savedCompanyInfo[prop] = assignFromProps(prop);
              });

              if (!savedCompanyInfo.googlePlaceId) {
                savedCompanyInfo.googlePlaceId = ADDRESS_DEFAULTS.NO_GOOGLE_PLACE_ID;
              }

              this.setState(
                {
                  companyInfo: companyInfoFactory(savedCompanyInfo),
                  isLoading: false,
                  ready: companyInfoField !== 'combined',
                },
                () => {
                  if (companyInfoField === 'combined') {
                    this.calculateCompanyFieldsToShow();
                  }
                }
              );
            }
          })
          .catch(() => {
            if (this._isMounted) {
              this.setState({ isLoading: false, ready: true });
            }
          });
      };

      onAddressConfirmed = () => {
        this.setState({
          isLoading: true,
        });
        const { selectedAddressId, validManualAddress, whitePagesKeys, invalidAddress } = this.state;

        if (!invalidAddress && selectedAddressId === ManualAddress.SUGGESTED && validManualAddress) {
          analytics.track(eventPage, 'suggested-address-confirmed');
          const updatedManualAddress = this.getUpdatedManualAddress();
          this.onNext(updatedManualAddress, updatedManualAddress);
        } else {
          if (invalidAddress) {
            analytics.track(eventPage, 'no-suggestion-address-confirmed');
          } else {
            analytics.track(eventPage, 'suggested-address-declined');
          }

          const { manualAddress } = this.state;
          const fullAddress = Object.values(whitePagesKeys)
            .filter((key) => manualAddress[key])
            .map((key) => manualAddress[key])
            .join(', ');
          manualAddress.formattedAddress = fullAddress;

          const { addressLat, addressLng } = manualAddress;

          if (Number.isNaN(addressLng) || Number.isNaN(addressLat)) {
            manualAddress.addressLng = 0;
            manualAddress.addressLat = 0;
          }

          this.onNext(manualAddress, manualAddress);
        }
      };

      addressValidationService = async () => {
        const { manualAddressValidationErrors, manualAddress } = this.state;
        const validManualAddress = await clientServiceApi.getAddressValidation(manualAddress);

        if (validManualAddress && validManualAddress.is_valid) {
          if (!validManualAddress.diff) {
            this.setState(
              {
                invalidAddress: false,
                validManualAddress,
              },
              () => {
                const updatedManualAddress = this.getUpdatedManualAddress();
                this.onNext(updatedManualAddress, updatedManualAddress);
              }
            );
          } else {
            if (validManualAddress.diff.length) {
              validManualAddress.diff.map((diff) => Object.assign(manualAddressValidationErrors, diff));
            }

            analytics.track(eventPage, 'alternative-suggested');
            this.setState({
              invalidAddress: false,
              confirmMode: true,
              isLoading: false,
              manualAddressValidationErrors,
              validManualAddress,
            });
          }
        } else {
          this.setState({
            invalidAddress: true,
            isLoading: false,
            validManualAddress: {},
          });
          analytics.track(eventPage, 'no-suggestion-found');

          if (validManualAddress.error) {
            analytics.track(eventPage, 'address-white-pages-validation-error', validManualAddress.error);
          }
        }
      };

      onAddressAddRequest = () => {
        this.setState({ isLoading: true });
        const { manualAddress } = this.state;
        const inputFields = ['addressLine1', 'city', 'state', 'zipCode'];
        let validationErrors = getValidationErrors('companyInfo', manualAddress, inputFields);

        if (isPOBox(manualAddress?.addressLine1)) {
          validationErrors = {
            ...validationErrors,
            addressLine1: 'form.addressAutocomplete.invalidPOBoxAddressLabel',
          };
        }

        this.setState({ validationErrors }, async () => {
          if (isValidationOk(validationErrors)) {
            await this.addressValidationService();
          } else {
            this.setState({
              isLoading: false,
            });
          }
        });
      };

      onManualAddressSelected = (id: string) => {
        analytics.track(eventPage, 'address-suggestion-selected');
        this.setState({
          selectedAddressId: id,
        });
      };

      onEditAddress = () => {
        const { validManualAddress } = this.state;

        if (validManualAddress) {
          const updatedManualAddress = this.getUpdatedManualAddress();
          this.setState(({ manualAddress }) => ({
            manualAddress: {
              ...manualAddress,
              ...updatedManualAddress,
            },
            isLoading: false,
            confirmMode: false,
            invalidAddress: false,
          }));
          analytics.track(eventPage, 'suggested-address-edited');
        }
      };

      getUpdatedManualAddress = (): Partial<AddressType> => {
        const { validManualAddress, whitePagesKeys } = this.state;
        const manualAddressFields = Object.keys(whitePagesKeys)
          .filter((key) => validManualAddress[key])
          .map((key) => {
            const field = { [key]: validManualAddress[key] };

            return field;
          });
        const updatedManualAddress = manualAddressFields.reduce((obj, diff) => {
          const whitePagesKey = Object.keys(diff)[0];
          const key = whitePagesKeys[whitePagesKey];
          // eslint-disable-next-line prefer-destructuring
          obj[key] = Object.values(diff)[0];

          return obj;
        }, {});
        const fullAddress = manualAddressFields.map((field) => Object.values(field)[0]).join(', ');
        updatedManualAddress.formattedAddress = fullAddress;
        updatedManualAddress.geometry = DEFAULT_LOCATION;
        updatedManualAddress.googlePlaceId = '';
        updatedManualAddress.aptNumber = undefined;

        return updatedManualAddress;
      };

      render() {
        const { ready } = this.state;

        if (!ready) {
          return <AreaLoader />;
        }

        return (
          <>
            <Component
              {...this.props}
              {...this.state}
              onPrev={this.onPrev}
              onNext={this.onNext}
              navigateToCompanyOnboardingPage={this.navigateToCompanyOnboardingPage}
              goExit={this.goExit}
              onChange={this.onChange}
              onManualAddressChange={this.onManualAddressChange}
              onAddressConfirmed={this.onAddressConfirmed}
              onAddressAddRequest={this.onAddressAddRequest}
              onManualAddressSelected={this.onManualAddressSelected}
              onEditAddress={this.onEditAddress}
            />
          </>
        );
      }
    }

    return connect(mapStateToProps, mapDispatchToProps)(injectIntl(ComponentWithCompanyInfoUpdates));
  };
}

export function withCompanyInfo() {
  return function (Component) {
    return function (props) {
      const { companyType } = useSelector(getCompanyInfo);
      const organizationPreferences = useOrganizationPreferences();

      return (
        <Component {...props} companyType={companyType} organizationPreferences={organizationPreferences}>
          {props.children}
        </Component>
      );
    };
  };
}
