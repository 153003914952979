import { useFeature } from '@melio/shared-web/dist/feature-flags';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { isCompanyLegalInfoDone } from 'src/pages/settings/records';
import { getCompanyInfo, getDeliveryMethods, getOwnedVendorHandle, getOwnedVendorId } from 'src/redux/user/selectors';
import { FeatureFlags } from 'src/utils/featureFlags';

export const useCanCreatePaymentRequest = () => {
  const company = useSelector(getCompanyInfo);
  const ownedVendorHandle = useSelector(getOwnedVendorHandle);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const orgDeliveryMethods = useSelector(getDeliveryMethods);
  const organizationPreferences = useOrganizationPreferences();
  const [isRequireLegalInfoOpen] = useFeature<boolean>('paymentrequest-require-legalinfo', false);
  const [isDeferredOnboardingEnabled] = useFeature<boolean>(FeatureFlags.EnableGetPaidDeferredOnboarding, false);
  const isFinishedDeferredOnboarding = Boolean(ownedVendorHandle && ownedVendorId);
  const legalInfoSatisfied = isRequireLegalInfoOpen
    ? organizationPreferences?.getPaidOverrideLegalInfo === 'true' || isCompanyLegalInfoDone(company)
    : true;
  const canCreatePaymentRequests = Boolean(ownedVendorHandle && !isEmpty(orgDeliveryMethods) && legalInfoSatisfied);
  const isFinishedOnboarding = isDeferredOnboardingEnabled ? isFinishedDeferredOnboarding : canCreatePaymentRequests;

  return {
    canCreatePaymentRequests,
    isFinishedOnboarding,
  };
};
