import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { BillFileLoader } from 'src/components/common/BillFileLoader';
import { MICreateOption } from 'src/components/common/MICreateOption';
import { MICreateOptionWithFileInput } from 'src/components/common/MICreateOptionWithFileInput';
import { MILink } from 'src/components/common/MILink';
import { MINotificationCard } from 'src/components/common/MINotificationCard';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { VStack } from 'src/core/ds/stack';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import { useCanCreatePaymentRequest } from 'src/hooks/useCanCreatePaymentRequests';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import invoiceStore from 'src/modules/invoices/invoices-store';
import UploadFileError from 'src/pages/bill/components/UploadFileError';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { getAccountingSoftwareDisplayName } from 'src/utils/accounting-software';
import { ACCEPT_FILES, DeliveryMethodOrigin, NotificationCardTypes } from 'src/utils/consts';
import { getPaidLocations } from '../locations';
import { PageProps } from './CreateInvoiceRouter';

type Props = {
  onLinkAction: () => void;
  goToUrl: (url: string, state?: { file: File }) => void;
  onPrev: () => void;
} & PageProps;

export const InvoiceManuallyImportPage = ({ onPrev, onExit, onLinkAction, goToUrl }: Props) => {
  const history = useHistory<{ origin?: string; redirectUrl?: string }>();
  const orgId = useSelector(getOrgId);
  const dispatch = useDispatch();
  const { canCreatePaymentRequests } = useCanCreatePaymentRequest();

  const { isConnected, connectedAccountingPlatform } = useFetchAccountingPlatformsAndData();

  const accountingSoftwareName =
    isConnected && connectedAccountingPlatform
      ? getAccountingSoftwareDisplayName(connectedAccountingPlatform.name)
      : undefined;
  const accountingSoftwareDisplayShortName = accountingSoftwareName && accountingSoftwareName.displayShortName;

  const isFileUploading = useSelector(invoiceStore.selectors.fileUpload.loading);
  const isFileUploadError = useSelector(invoiceStore.selectors.fileUpload.error);

  const onCancelOrRemoveFile = useCallback(() => {
    // using dispatch here because this slice CreateSlice that doesn't have a dispatcher
    dispatch(invoiceStore.actions.clearFileUpload.clear());
  }, [dispatch]);

  const onManualInvoiceClick = () => {
    goToUrl(getPaidLocations.create.invoice);
  };

  const invoiceActions = useStoreActions(invoiceStore);

  const onRemove = () => {
    invoiceStore.actions.clearFileUpload.clear();
  };

  const onSelectFile = async (file: File) => {
    await invoiceActions.fileUpload({ orgId, file });
    goToUrl(getPaidLocations.create.fileInvoice);
  };

  const onSelectBatchFile = (file: File) => {
    goToUrl(getPaidLocations.create.batchInvoices.batch, { file });
  };

  useEffect(() => {
    if (!canCreatePaymentRequests) {
      history.push(
        generatePath(onboardingLocations.vendorCompanyInfo.link, {
          orgId,
        }),
        {
          origin: DeliveryMethodOrigin.CREATE_OWNED_VENDOR_FROM_NEW_REQUEST,
        }
      );
    }

    return onRemove;
  }, [history, orgId, canCreatePaymentRequests]);

  if (isFileUploading) {
    return <BillFileLoader onCancelAction={onRemove} />;
  }

  if (isFileUploadError) {
    return <UploadFileError handleUploadCancel={onCancelOrRemoveFile} goManual={onManualInvoiceClick} />;
  }

  return (
    <StepLayoutPage
      relativeStep={0}
      goExit={onExit}
      onPrev={onPrev}
      title="getPaid.new.create.importManualInvoiceTitle"
      subtitle="getPaid.new.create.importManualInvoiceSubTitle"
      innerSize={100}
      isHideActions
    >
      <Flex direction="column" alignItems="center" justifyContent="center" textAlign="left">
        {accountingSoftwareDisplayShortName ? (
          <Box mb={10} w="full" maxW="45rem">
            <MINotificationCard
              title={{
                label: 'getPaid.new.create.connect.disclaimerOnManualInvoicesCreation.title',
                values: { integrationName: accountingSoftwareDisplayShortName },
              }}
              subtitle={{
                label: 'getPaid.new.create.connect.disclaimerOnManualInvoicesCreation.subTitle',
                values: { integrationName: accountingSoftwareDisplayShortName },
              }}
              type={NotificationCardTypes.WARNING}
            />
          </Box>
        ) : null}
        <MICreateOptionWithFileInput
          icon="icon-upload-file"
          label="getPaid.new.create.importInvoiceFile"
          description="getPaid.new.create.importInvoiceFileDescription"
          accept={ACCEPT_FILES.BILL_ATTACHMENT}
          onSelectFile={onSelectFile}
        />
        <MICreateOption
          icon="icon-add-manually"
          label="getPaid.new.create.addInvoiceManually"
          description="getPaid.new.create.addInvoiceManuallyDescription"
          onClick={onManualInvoiceClick}
        />
        <MICreateOptionWithFileInput
          icon="icon-upload-file"
          label="getPaid.new.create.importInvoicesList"
          description="getPaid.new.create.importInvoicesListDescription"
          accept={ACCEPT_FILES.TEXT_LISTS}
          onSelectFile={onSelectBatchFile}
          linkLabel="getPaid.new.create.importInvoicesListLink"
          linkAction={onLinkAction}
        />
      </Flex>
      <VStack w="full" justifyContent="center" mt="8" textAlign="left">
        <MILink label="general.cancel" to={onExit} />
      </VStack>
    </StepLayoutPage>
  );
};
