import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useStructuredSelectors } from 'src/helpers/redux/useStructuredSelectors';
import { useSiteContext } from 'src/hoc/withSiteContext';
import authenticationStore from 'src/modules/auth/auth-store';
import { profileStore } from 'src/modules/profile/profile-store';
import { companiesLocations } from 'src/pages/companies/locations';
import { globalLocations } from 'src/pages/locations';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { spendManagementLocations } from 'src/pages/spend-management/locations';
import { getCompanyInfo, getOwnedVendorId, getProfile } from 'src/redux/user/selectors';
import { RegistrationFlow } from 'src/utils/consts';
import locations from 'src/utils/locations';

type ReturnValue = {
  successRedirectUrl: string;
};

export const useSuccessRegisterRedirectUrl = (): ReturnValue => {
  const site = useSiteContext();
  const profile = useSelector(getProfile);
  const ownedVendorId = useSelector(getOwnedVendorId);
  const companyInfo = useSelector(getCompanyInfo);
  const organizations = useSelector(profileStore.selectors.getOrganizations);
  const { isFirstTimeVisitedRegularMelio } = useStructuredSelectors(authenticationStore.selectors.registration());

  const successRedirectUrl = useMemo(() => {
    if (ownedVendorId) {
      return generatePath(onboardingLocations.vendorCompanyInfo.index, {
        orgId: profile.orgId,
      });
    }

    if (profile.registrationFlow === RegistrationFlow.SPEND_MANAGEMENT) {
      return generatePath(spendManagementLocations.index, {
        orgId: profile.orgId,
      });
    }

    if (companyInfo.allowedActions.canDoPayments) {
      return organizations.length > 1
        ? generatePath(companiesLocations.index, { orgId: profile.orgId })
        : locations.MainApp.dashboard.url({ orgId: profile.orgId });
    }

    const shouldRedirectToQuickbookMultiOrgOnboarding =
      isFirstTimeVisitedRegularMelio && organizations.length > 1 && organizations.every((org) => org.isIntuitLinked);

    if (shouldRedirectToQuickbookMultiOrgOnboarding) {
      return globalLocations.quickbookMultiOrgOnboarding.companyType;
    }

    if (profile.registrationFlow === RegistrationFlow.QUICKBOOKS) {
      return generatePath(onboardingLocations.quickbooks, {
        orgId: profile.orgId,
      });
    }

    return generatePath(site.onboardingEntryUrl, {
      orgId: profile.orgId,
    });
  }, [
    ownedVendorId,
    profile.orgId,
    site.onboardingEntryUrl,
    isFirstTimeVisitedRegularMelio,
    companyInfo.allowedActions.canDoPayments,
    organizations.length,
    profile.registrationFlow,
  ]);

  return {
    successRedirectUrl,
  };
};
