import { useCallback, useMemo } from 'react';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { DeliveryMethodOrigin } from 'src/utils/consts';
import { VendorCompanyAddedPageContent } from '../components/VendorCompanyAddedPageContent';
import { VendorCompanyAddedPageMSNContent } from '../components/VendorCompanyAddedPageMSNContent';
import { VendorCompanyInfoConfig } from '../context/VendorCompanyInfoContext';
import { Origin } from './types';
import { createLabel, getPrefix, handleLegalInfoNextStep, handleLegalInfoPrevStep } from './utils';

const getAddedPageContent = (origin: Origin) => {
  if (origin === DeliveryMethodOrigin.MSN_PORTAL_GENERIC_LINK_CREATION) {
    return <VendorCompanyAddedPageMSNContent />;
  }

  return <VendorCompanyAddedPageContent />;
};

const createConfiguration = ({
  origin,
  onLegalInfoNext,
  onLegalInfoPrev,
}: {
  origin: Origin;
  onLegalInfoNext: () => void;
  onLegalInfoPrev: () => void;
}): VendorCompanyInfoConfig => {
  const prefix = getPrefix(origin);

  return {
    VendorCompanyLink: {
      title:
        origin === DeliveryMethodOrigin.CREATE_OWNED_VENDOR_FROM_NEW_REQUEST
          ? 'onboarding.vendorCompanyInfo.link.newPaymentRequestOriginTitle'
          : createLabel({ subject: 'link', prefix, suffix: 'title' }),
      subTitle: createLabel({ subject: 'link', prefix, suffix: 'subTitle' }),
      nextLabel: createLabel({ subject: 'link', prefix, suffix: 'nextLabel' }),
      inputTitle: createLabel({ subject: 'link', prefix, suffix: 'inputTitle' }),
      inputHint: createLabel({ subject: 'link', prefix, suffix: 'inputHint' }),
    },
    VendorCompanyLogo: {
      title: createLabel({ subject: 'logo', prefix, suffix: 'title' }),
      subTitle: createLabel({ subject: 'logo', prefix, suffix: 'subTitle' }),
      skipLabel: createLabel({ subject: 'logo', prefix, suffix: 'skipLabel' }),
      nextLabel: createLabel({ subject: 'logo', prefix, suffix: 'nextLabel' }),
    },
    VendorCompanyLegalInfo: {
      title: createLabel({ subject: 'legalInfo', prefix, suffix: 'title' }),
      subTitle: createLabel({ subject: 'legalInfo', prefix, suffix: 'subTitle' }),
      nextLabel: createLabel({ subject: 'legalInfo', prefix, suffix: 'nextLabel' }),
      onNext: onLegalInfoNext,
      onPrev: onLegalInfoPrev,
    },
    VendorCompanyAdded: {
      title: createLabel({ subject: 'added', prefix, suffix: 'title' }),
      subTitle: createLabel({ subject: 'added', prefix, suffix: 'subTitle' }),
      secondaryActionButtonLabel:
        origin !== DeliveryMethodOrigin.MSN_PORTAL_GENERIC_LINK_CREATION
          ? 'onboarding.vendorCompanyInfo.added.skipLabel'
          : '',
      nextLabel: createLabel({ subject: 'added', prefix, suffix: 'nextLabel' }),
      content: getAddedPageContent(origin),
    },
  };
};

export const useCreateVendorCompanyInfoConfig = (origin: Origin = '') => {
  const { navigate } = useNavigator();

  const onLegalInfoPrev = useCallback(() => handleLegalInfoPrevStep({ origin, navigate }), [navigate, origin]);
  const onLegalInfoNext = useCallback(() => handleLegalInfoNextStep({ origin, navigate }), [navigate, origin]);

  return useMemo(() => createConfiguration({ origin, onLegalInfoNext, onLegalInfoPrev }), [
    origin,
    onLegalInfoNext,
    onLegalInfoPrev,
  ]);
};
