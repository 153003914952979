import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import config from 'src/config';
import Box from 'src/core/ds/box';
import { Button, ButtonSizes, ButtonVariants } from 'src/core/ds/button';
import Flex from 'src/core/ds/flex';
import { Link } from 'src/core/ds/link';
import { pushNotification } from 'src/services/notifications';
import { NotificationVariant } from 'src/utils/consts';

type Props = {
  link: string;
  clipboardText: string;
};

function replaceDomainWithTrailingDots(url: string) {
  return url.replace(config.web.baseUrl, '...');
}

export function CopyCompanyHandle({ link, clipboardText }: Props) {
  const onCopy = () => {
    pushNotification({
      type: NotificationVariant.INFO,
      msg: 'requests.melioMeLinkCard.toastMessage',
    });
  };

  return (
    <Flex flex={1} alignItems="center">
      <Flex flex={1} minW={0} flexDirection="column" alignItems="flex-start">
        <Box mb="2" textStyle="body4Semi" color="grey.600">
          <MIFormattedText label="getPaid.new.create.shareModal.clickToPreview" />
        </Box>
        <Link
          href={link}
          isExternal
          isTruncated
          width="full"
          wordBreak="break-all"
          display="block"
          data-testid="copy-company-handler"
          textStyle="body1"
          textDecor="none"
          color="primary.500"
        >
          {replaceDomainWithTrailingDots(link)}
        </Link>
      </Flex>
      <Flex ml="4">
        <CopyToClipboard onCopy={onCopy} text={clipboardText} data-testid="copy-link-to-clipboard">
          <Button label="general.copy" size={ButtonSizes.md} variant={ButtonVariants.tertiary} />
        </CopyToClipboard>
      </Flex>
    </Flex>
  );
}
