import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useCanCreatePaymentRequest } from 'src/hooks/useCanCreatePaymentRequests';
import { useFetchAccountingPlatformsAndData } from 'src/hooks/useFetchAccountingPlatformsAndData';
import createRequestImage from 'src/images/general/request-empty-2-state-icon.svg';
import requestEmptyStateIcon from 'src/images/general/request-empty-state-icon.svg';
import customersStore from 'src/modules/customers/customers-store';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import {
  getNoFilteredItemsMessage,
  getNoInvoicesEverAddedMessage,
  getNoItemsMessage,
} from 'src/pages/get-pro/components/empty/utils';
import { GET_PAID_DISABLED_EMPTY_MESSAGE } from 'src/pages/get-pro/consts';
import useGetProListParams from 'src/pages/get-pro/hooks/useGetProListParams';
import useLoadGetProList from 'src/pages/get-pro/hooks/useLoadGetProList';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { useQueryState } from 'src/utils/hooks';
import { ContactType } from 'src/utils/types';

type EmptyStateParams = {
  title?: string;
  exp: string;
  expValues?: Record<string, any>;
  cta?: string;
  ctaAction?: () => void;
  icon?: string;
};

const useEmptyStateMessage = (): EmptyStateParams => {
  const [historyPush] = useHistoryWithOrgId();
  const { listParams, filter, search } = useGetProListParams();
  const { tabsSummary, hasPaymentRequests } = useLoadGetProList(listParams);
  const { status: tab } = listParams.filters;
  const { connectedAccountingPlatform, isConnected } = useFetchAccountingPlatformsAndData();
  const { isFinishedOnboarding } = useCanCreatePaymentRequest();
  const itemsExistsOnThisTab = tabsSummary?.[tab]?.totalCount > 0;

  const [contactId] = useQueryState('contactId');
  const customer = useSelector<any, ContactType>(customersStore.selectors.fetch.byId(contactId));

  const createLinkUrl = useCallback(() => historyPush({ path: onboardingLocations.vendorCompanyInfo.link }), [
    historyPush,
  ]);

  const createInvoice = useCallback(() => historyPush({ path: getPaidLocations.create.options }), [historyPush]);

  if (!isFinishedOnboarding) {
    return {
      ...GET_PAID_DISABLED_EMPTY_MESSAGE,
      ctaAction: createLinkUrl,
      icon: requestEmptyStateIcon,
    };
  } else if (!hasPaymentRequests) {
    return {
      ...getNoInvoicesEverAddedMessage(isConnected, createInvoice),
      icon: createRequestImage,
    };
  }

  return itemsExistsOnThisTab && ((filter && filter !== 'all') || search || customer?.companyName)
    ? getNoFilteredItemsMessage({
        tab,
        filter,
        search,
        customerCompanyName: customer?.companyName,
      })
    : getNoItemsMessage({
        tab,
        filter,
        isConnected,
        accountingSoftwareName: connectedAccountingPlatform?.name,
        createInvoice,
        search,
      });
};

export default useEmptyStateMessage;
