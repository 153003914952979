import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import invoiceStore from 'src/modules/invoices/invoices-store';
import { useHistoryWithOrgId } from 'src/modules/navigation/hooks/useHistoryWithOrgId';
import { paymentRequestsStore } from 'src/modules/payment-requests/payment-requests-store';
import GetProModalContext from 'src/pages/get-pro/components/table/GetProModalContext';
import useGetProListParams from 'src/pages/get-pro/hooks/useGetProListParams';
import useLoadGetProList from 'src/pages/get-pro/hooks/useLoadGetProList';
import { getProLocations } from 'src/pages/get-pro/locations';
import { getOrgId } from 'src/redux/user/selectors';
import { pushNotification } from 'src/services/notifications/notificationService';
import { ButtonsDirections, DialogVariants, NotificationVariant } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';

type Action = (ids?: string[]) => Promise<void>;
type ActionWithCallback = (ids?: string[], callback?: () => void) => Promise<void>;

type MultiItemsActions = {
  sendRequests: Action;
  markAsPaidInvoices: ActionWithCallback;
  removeInvoices: Action;
  markAsPaidRequests: ActionWithCallback;
  cancelRequests: Action;
};

const useGetProMultiItemsActions = (): MultiItemsActions => {
  const [historyPush] = useHistoryWithOrgId();
  const orgId = useSelector(getOrgId);
  const query = useQueryString();
  const invoiceActions = useStoreActions(invoiceStore);
  const paymentRequestsActions = useStoreActions(paymentRequestsStore);
  const { listParams } = useGetProListParams();
  const { loadGetProList } = useLoadGetProList(listParams);
  const { showModal, setClear } = useContext(GetProModalContext);
  const sendRequests: Action = async () => {
    historyPush({
      path: getProLocations.batchSend,
      query,
    });
  };
  const markAsPaidInvoices: ActionWithCallback = async (ids, callback) => {
    const items = ids?.map((id) => ({ id }));
    await invoiceActions.proBatchMarkAsPaid({ orgId, items });
    callback?.();
    await loadGetProList();
  };
  const markAsPaidRequests: ActionWithCallback = async (ids, callback) => {
    const onMarkedAsPaidRequests = async () => {
      const items = ids?.map((id) => ({ id }));
      await paymentRequestsActions.proBatchMarkAsPaid({ orgId, items });
      callback?.();
      await loadGetProList();
    };

    showModal &&
      showModal({
        confirm: onMarkedAsPaidRequests,
        title: 'getPro.confirmationModals.markedAsPaidRequests.title',
        description: 'getPro.confirmationModals.markedAsPaidRequests.description',
        confirmText: 'getPro.confirmationModals.markedAsPaidRequests.confirm',
        cancelText: 'getPro.confirmationModals.markedAsPaidRequests.cancel',
        hideIcon: true,
        minHeight: '31.2rem',
        buttonsDirection: ButtonsDirections.HORIZONTAL,
        variant: DialogVariants.SUCCESS,
        textValues: {
          numOfRequests: ids?.length,
        },
      });
  };
  const removeInvoices: Action = async (ids) => {
    const onRemove = async () => {
      setClear();
      await invoiceActions.proBatchDelete({ orgId, ids });
      pushNotification({
        type: NotificationVariant.INFO,
        msg: 'getPro.table.item.toasts.removeBatchInvoices.success',
        textValues: {
          count: ids?.length,
        },
      });
      await loadGetProList();
    };
    showModal &&
      showModal({
        confirm: onRemove,
        title: 'getPro.confirmationModals.removeInvoices.title',
        description: 'getPro.confirmationModals.removeInvoices.description',
        confirmText: 'getPro.confirmationModals.removeInvoices.confirm',
        cancelText: 'getPro.confirmationModals.removeInvoices.cancel',
        textValues: {
          numOfInvoices: ids?.length,
        },
      });
  };
  const cancelRequests: Action = async (ids) => {
    const onCancel = async (remove: boolean) => {
      setClear();

      if (remove) {
        await paymentRequestsActions.proBatchDelete({ orgId, ids });
        pushNotification({
          type: NotificationVariant.INFO,
          msg: 'getPro.table.item.toasts.removeBatchRequests.success',
          textValues: {
            count: ids?.length,
          },
        });
      } else {
        await paymentRequestsActions.proBatchCancel({ orgId, ids });
        pushNotification({
          type: NotificationVariant.INFO,
          msg: 'getPro.table.item.toasts.cancelBatchRequests.success',
          textValues: {
            count: ids?.length,
          },
        });
      }

      await loadGetProList();
    };
    showModal &&
      showModal({
        confirm: onCancel,
        title: 'getPro.confirmationModals.removePaymentRequests.title',
        description: 'getPro.confirmationModals.removePaymentRequests.description',
        confirmText: 'getPro.confirmationModals.removePaymentRequests.confirm',
        cancelText: 'getPro.confirmationModals.removePaymentRequests.cancel',
        textValues: {
          numOfRequests: ids?.length,
        },
        checkbox: {
          value: false,
          label: 'getPro.confirmationModals.removePaymentRequests.checkboxLabel',
        },
      });
  };

  return {
    sendRequests,
    markAsPaidInvoices,
    removeInvoices,
    markAsPaidRequests,
    cancelRequests,
  };
};

export default useGetProMultiItemsActions;
