import isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Redirect } from 'react-router-dom';
import { IdType, SortingRule } from 'react-table';
import { CustomFeed } from 'src/components/braze/CustomFeed';
import { FullPageLayout } from 'src/components/layout/FullPageLayout';
import { DateTableRowType } from 'src/components/shared/tables/PaymentsTable/types';
import Box from 'src/core/ds/box';
import Flex from 'src/core/ds/flex';
import { useUpdateEffect } from 'src/core/ds/hooks';
import { useModal } from 'src/helpers/react/useModal';
import { useBreak } from 'src/hoc';
import { useCanCreatePaymentRequest } from 'src/hooks/useCanCreatePaymentRequests';
import customersStore from 'src/modules/customers/customers-store';
import { useProcessingFeeData } from 'src/pages/get-paid/hooks/useProcessingFeeData';
import { getPaidLocations } from 'src/pages/get-paid/locations';
import { AchTransferFeeNotification } from 'src/pages/get-pro/components/AchTransferFeeNotification';
import { GET_ALL_FILTER } from 'src/pages/get-pro/consts';
import { getOrgId } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { useQueryState } from 'src/utils/hooks';
import { ContactType } from 'src/utils/types';
import ProCommonDialog from './components/dialogs/ProCommonDialog';
import CardDrawer from './components/drawer/CardDrawer';
import Header from './components/header/Header';
import { Messages } from './components/message/Messages';
import { WelcomeModal } from './components/modals/WelcomeModal';
import { TableContentState } from './components/table/consts';
import GetProModalContext from './components/table/GetProModalContext';
import GetProTable from './components/table/GetProTable';
import { useGetTableContentState } from './components/table/hooks/useGetTableContentState';
import { useFiltersState } from './components/tableSummary/hooks/useFiltersState';
import { TableSummary } from './components/tableSummary/TableSummary';
import { Tabs } from './components/tabs/Tabs';
import { useGetProEnabled } from './hooks/useGetProEnabled';
import useGetProListParams from './hooks/useGetProListParams';
import useLoadGetProList from './hooks/useLoadGetProList';
import { useShowWelcomeModal } from './hooks/useShowWelcomeModal';
import {
  headerStyle,
  noContentStateStyle,
  pageContentStyle,
  scrollHiderStyle,
  summaryStyle,
  sxForDashboardCard,
  tabsStyle,
} from './styles';
import { createQueryDefaultSortBy } from './utils';

const contentStateToShowInsideTableBody: Array<TableContentState> = [
  TableContentState.CONTENT,
  TableContentState.EMPTY_FILTER_OR_SEARCH_RESULT,
  TableContentState.LOADING,
];

export const GetProPage = () => {
  const orgId = useSelector(getOrgId);
  const pageRef = useRef<HTMLDivElement>(null);
  const [clearSelected, setClearSelected] = useState(false);
  const [selectedIds, setSelectedIds] = useState<Record<IdType<string>, boolean>>({});
  const showFilters = Object.keys(selectedIds).length === 0;
  const { shouldShowWelcomeModal, setWelcomeModalWasShown } = useShowWelcomeModal();
  const [commonModalMessage, showCommonModal] = useModal(ProCommonDialog, {});
  const [welcomeModalMessage, showWelcomeModal] = useModal(WelcomeModal, {
    onDismiss: () => setWelcomeModalWasShown(true),
  });
  const [isAchTransferFeeNotificationDisplayed, setAchTransferFeeNotificationPresentationState] = useState<boolean>(
    true
  );

  const { listParams, filter, search, setFilter, setStatuses, setSort, statuses } = useGetProListParams();
  const { loadStatus, tabsSummary, loadGetProList, data: items, currentDataLastUpdated } = useLoadGetProList(
    listParams
  );
  const { isDesktop } = useBreak();

  const {
    filters: { status: tab, sort },
  } = listParams;
  const totalItems = loadStatus?.totalCount || 0;
  const [contactId] = useQueryState('contactId');
  const customer = useSelector<any, ContactType>(customersStore.selectors.fetch.byId(contactId));
  const isLoading = loadStatus?.totalCount === undefined;
  const { isFinishedOnboarding } = useCanCreatePaymentRequest();
  const itemsExistsOnCurrentTab = tabsSummary?.[tab]?.totalCount > 0;
  const isFilterOrSearching = Boolean(
    (filter && filter !== GET_ALL_FILTER[tab]) || search || customer?.companyName || statuses
  );
  const isFilterOrSearchResultsEmpty = totalItems === 0 && itemsExistsOnCurrentTab && isFilterOrSearching;
  const isAllResultsEmpty = totalItems === 0 && filter === GET_ALL_FILTER[tab];
  const [contentState, content] = useGetTableContentState({
    loading: isLoading,
    error: !!loadStatus?.error,
    isFinishedOnboarding,
    isAllResultsEmpty,
    isFilterOrSearchResultsEmpty,
  });
  const { shouldPresentAchProcessingFeeNotification } = useProcessingFeeData();

  const contentStateOutsideTableBody = contentState && !contentStateToShowInsideTableBody.includes(contentState);
  const getProEnabled = useGetProEnabled();

  const {
    selectedDateFilter: lastSelectedDateFilter,
    selectedStatusesFilter: lastSelectedStatusesFilter,
  } = useFiltersState(tab);

  const setClear = () => {
    setSelectedIds({});
    setClearSelected(true);
  };

  const toggleClearSelected = () => {
    setClearSelected((prevValue) => !prevValue);
  };

  useEffect(() => {
    let showModalTimeout: number;

    if (shouldShowWelcomeModal) {
      showModalTimeout = setTimeout(() => {
        showWelcomeModal();
      }, 3000);
    }

    return () => {
      clearTimeout(showModalTimeout);
    };
  }, [shouldShowWelcomeModal]);

  useEffect(() => {
    setSelectedIds({});

    if (pageRef.current) {
      pageRef.current.scrollIntoView();
    }
  }, [filter, listParams.filters.status, listParams.filters.start, pageRef]);

  useEffect(() => {
    if (search) {
      setFilter(undefined);
      setStatuses(undefined);
    }
  }, [search]);

  useEffect(() => {
    // The `isDesktop` check is required to eliminate the state change. Otherwise, when we're in mobile layout
    // and the page is redirected to the basic mode, the state's change cancels the redirect
    if (!sort && isDesktop) {
      const initialSort = createQueryDefaultSortBy(tab);
      setSort(initialSort);
    }
  }, [sort, setSort, isDesktop]);

  useEffect(() => {
    if (isFinishedOnboarding && sort) {
      loadGetProList();
    }
  }, [loadGetProList, isFinishedOnboarding]);

  const onChangeSort = (sort: SortingRule<DateTableRowType>[]) => {
    const paramSort = sort.map((s) => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(';');
    const currSort = isEmpty(listParams.filters.sort) ? '' : listParams.filters.sort;

    if (currSort !== paramSort) {
      analytics.trackAction(`getPro.${tab}.tableSort`, { tab, sort });
      setSort(paramSort);
    }
  };

  useUpdateEffect(() => {
    const concatenatedSelectedStatusesFilter = (lastSelectedStatusesFilter ?? []).join(',');

    // Updating the filters to the filters that were on the last time we visited the tab
    setStatuses(concatenatedSelectedStatusesFilter);
    setFilter(lastSelectedDateFilter);
  }, [tab]);

  if (!isDesktop || !getProEnabled) {
    const { action, actionParams } = listParams.filters;

    let search;

    if (action) {
      search = `&action=${action}`;

      if (actionParams) {
        search = `${search}&action-params=${actionParams}`;
      }
    }

    return <Redirect to={{ pathname: generatePath(getPaidLocations.dashboard, { orgId }), search }} />;
  }

  return (
    <FullPageLayout noPadding>
      <Box ref={pageRef}>
        <GetProModalContext.Provider value={{ showModal: showCommonModal, setClear }}>
          {commonModalMessage}
          {welcomeModalMessage}
          <CardDrawer setClearSelected={setClear} selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
          <Box __css={scrollHiderStyle} />
          <Box __css={headerStyle}>
            {shouldPresentAchProcessingFeeNotification && isAchTransferFeeNotificationDisplayed && (
              <AchTransferFeeNotification
                removeNotification={() => setAchTransferFeeNotificationPresentationState(false)}
              />
            )}
            <Header />
            <CustomFeed feedType="getpaiddashboard_above" sxForCard={sxForDashboardCard} />
          </Box>
          <Box __css={pageContentStyle}>
            <Box __css={tabsStyle}>
              <Tabs />
            </Box>
            {contentStateOutsideTableBody ? (
              <Box __css={noContentStateStyle}>{content}</Box>
            ) : (
              <Flex direction="column" mb={8}>
                <Box __css={summaryStyle}>
                  <TableSummary
                    setSelectedIds={setSelectedIds}
                    selectedIds={selectedIds}
                    showFilters={showFilters}
                    lastSelectedStatusesFilter={lastSelectedStatusesFilter}
                  />
                </Box>
                <GetProTable
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                  clearSelected={clearSelected}
                  toggleClearSelected={toggleClearSelected}
                  loadStatus={loadStatus}
                  loadGetProList={loadGetProList}
                  items={items}
                  currentDataLastUpdated={currentDataLastUpdated}
                  content={content}
                  contentState={contentState}
                  onChangeSort={onChangeSort}
                />
              </Flex>
            )}
          </Box>
          <Messages />
        </GetProModalContext.Provider>
      </Box>
    </FullPageLayout>
  );
};
