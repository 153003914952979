import curry from 'lodash/curry';
import flow from 'lodash/flow';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import memoize from 'memoize-one';
import { OptionType } from 'src/components/common/DropDown/MIDropDown';
import invoicesStore from 'src/modules/invoices/invoices-store';
import type { InvoiceType } from 'src/modules/invoices/types';
import { BillDataResult } from 'src/modules/invoices/utils';
import { ContactType } from 'src/utils/types';
import { InvoiceDetailType } from './components/form/InvoiceDetailsForm';
import { SendPaymentRequestType } from './components/form/SendPaymentRequestForm';
import { LabelValuesType } from './components/InvoiceFileUpload';
import { TEMP_CUSTOMER_ID } from './constants';

const NUM_OF_SAMPLES = 3;

export const getPreDefinedCustomer = (customerName?: string): OptionType[] =>
  customerName ? [{ label: customerName, value: TEMP_CUSTOMER_ID }] : [];

export const getCustomerOptions = (customerList: ContactType[]): OptionType[] =>
  customerList.map((contact) => ({
    label: contact.companyName,
    value: contact.id,
  }));

export const getCustomerById = (customerList: ContactType[], customerId?: string): ContactType | undefined =>
  customerList.find((customer) => customer?.id?.toString() === customerId);

export const getCustomerEmailById = (customerList: ContactType[], customerId?: number): string | undefined =>
  getCustomerById(customerList, customerId?.toString())?.contactEmail;

export const getFileInvoiceModel = (billData: BillDataResult, existingCustomer?: OptionType): InvoiceDetailType => ({
  customerId: existingCustomer?.value || TEMP_CUSTOMER_ID,
  customerName: billData.customerNameValue || '',
  totalAmount: billData.totalAmount || '',
  invoiceNumber: billData.invoiceNumber || '',
  dueDate: billData.dueDate || '',
});

export const getInvoiceModel = (customerList: ContactType[], customerId?: string): InvoiceDetailType => ({
  customerId: Number(customerId) || null,
  customerName: getCustomerById(customerList, customerId)?.companyName || '',
  totalAmount: '',
  invoiceNumber: '',
  dueDate: '',
});

export const shouldAttachInvoiceFile = (invoice: InvoiceType) =>
  invoice.shouldAttachInvoice || Boolean(invoice.files?.length);

export const getPaymentRequestModel = (
  paymentRequestId: string,
  invoice?: InvoiceType,
  customer?: Partial<ContactType>
): SendPaymentRequestType => {
  if (invoice) {
    const { customer: invoiceCustomer, customerNote } = invoice;

    return {
      paymentRequestId: Number(paymentRequestId),
      customerEmail: customer?.contactEmail || '',
      customerNote: customerNote || '',
      customerName: invoiceCustomer?.contactName || '',
      contactPhone: customer?.contactPhone || '',
      shouldAttachInvoice: invoice && shouldAttachInvoiceFile(invoice),
    };
  }

  return {
    paymentRequestId: Number(paymentRequestId),
    customerEmail: '',
    customerNote: '',
    customerName: '',
    contactPhone: '',
    shouldAttachInvoice: false,
  };
};

export const getLabelAndValues = (
  isMobile: boolean,
  labelValues: LabelValuesType
): { label: string; labelValues: LabelValuesType } => {
  if (isMobile) {
    return {
      label: 'getPaid.new.create.dragAndDropMobile',
      labelValues: {},
    };
  }

  return {
    label: 'getPaid.new.create.dragAndDrop',
    labelValues,
  };
};

const resetScroll = () => window.scrollTo(0, 0);

const filterEmpty = (fileContent) => fileContent.filter((item) => !isEmpty(item));

const fileColumns = flow([head, keys, filterEmpty]);

export const getInvoicesSelector = () => invoicesStore.selectors;

const getValuesForColumn = (maxSamples: number, fileContent: { [key: string]: string }, column: string) => {
  const reduceIterator = (res, obj) =>
    res.length < maxSamples && !isEmpty(obj[column]) ? res.concat([obj[column]]) : res;

  return reduce(fileContent, reduceIterator, [] as string[]);
};

const curriedGetValuesForColumn = curry(getValuesForColumn);

const getSampleColumns = memoize((fileContent) => {
  if (!fileContent?.length) {
    return [];
  }

  const getFileObj = (fileColumns) => fileColumns.map((name) => ({ name, values: getSamples(name) }));
  const filterFileObj = (fileObj) => fileObj.filter((column: { values: string[] }) => column.values.length > 0);

  const getSamples = curriedGetValuesForColumn(NUM_OF_SAMPLES, fileContent);

  return flow([fileColumns, getFileObj, filterFileObj])(fileContent);
});

export { getSampleColumns, resetScroll };
