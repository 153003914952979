import { ChangeEvent } from 'react';
import styled from 'styled-components';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import Box from 'src/core/ds/box';
import { TextField } from 'src/core/ds/form/fields';
import { useUnifiedOnboardingFlag } from 'src/hooks/useUnifiedOnboardingFlag';
import { handleInputChange } from 'src/utils/input';
import { FieldType } from 'src/utils/types';
import { MELIO_ME_LINK_HOSTNAME } from '../consts';
import { useVendorCompanyInfoConfig } from '../context/VendorCompanyInfoContext';

type Props = {
  onPrev?: () => void;
  goExit?: () => void;
  onNext: () => void;
  onChange: (arg0: FieldType) => void;
  handle: string;
  isLoading?: boolean;
  errorMessage?: string;
};

export const VendorCompanyLink = ({ onPrev, goExit, onNext, onChange, isLoading, errorMessage, handle }: Props) => {
  const isUnifiedOnboardingOpen = useUnifiedOnboardingFlag();

  const getProgressBarRelativeStep = () => (isUnifiedOnboardingOpen ? 8 / 15 : 5 / 10);
  const { title, subTitle, nextLabel, inputTitle, inputHint } = useVendorCompanyInfoConfig<'VendorCompanyLink'>({
    page: 'VendorCompanyLink',
  });

  return (
    <StepLayoutPage
      title={title}
      subtitle={subTitle}
      onPrev={onPrev}
      goExit={goExit}
      onNext={onNext}
      isLoading={isLoading}
      relativeStep={getProgressBarRelativeStep()}
      nextLabel={nextLabel}
    >
      <HandleContainer>
        <Box pos="absolute" top={6} textStyle="body1" color="grey.700" opacity={0.5}>
          {MELIO_ME_LINK_HOSTNAME}
        </Box>
        <Box>
          <TextField
            id="handleInput"
            label={inputTitle}
            helperText={inputHint}
            value={handle}
            onSubmit={onNext}
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleInputChange({ event, onChange })}
            isDisabled={isLoading}
            errorMessage={errorMessage}
            autoFocus
            isRequired
          />
        </Box>
      </HandleContainer>
    </StepLayoutPage>
  );
};

const HandleContainer = styled.div`
  position: relative;

  #handleInput {
    padding-left: 10rem;
    box-sizing: border-box;
    line-height: ${(props) => props.theme.text.lineHeight.bigTitleM};
  }
`;
