import { useEffect } from 'react';
import { MIFormattedText } from 'src/components/common/MIFormattedText';
import { SuccessLayoutPage } from 'src/components/layout/SuccessLayoutPage';
import Box from 'src/core/ds/box';
import { IllustrationName } from 'src/core/ds/illustration';
import { Link } from 'src/core/ds/link';
import { LEARN_MORE_LINK } from 'src/flows/add-bank-account-flow/consts';
import { analytics } from 'src/services/analytics';
import { FlowCustomizationsType } from '../../types';

type Props = {
  isDeliveryMethod: boolean;
  saveAndContinue: () => void;
  flowCustomizations?: FlowCustomizationsType;
};

export const AddPlaidBankAccountSuccessPage = ({ isDeliveryMethod, saveAndContinue, flowCustomizations }: Props) => {
  const { flowEntryPoint } = flowCustomizations || {};
  const eventPage = `add-${isDeliveryMethod ? 'delivery-method' : 'funding-source'}`;
  const goLearnMore = () => {
    analytics.track(eventPage, 'learn-more');
  };

  const Footer = (
    <Box mt={5} textStyle="body3" data-testid="learn-more-footer">
      <MIFormattedText
        label="flows.addBankAccount.success.footer"
        values={{
          learnMore: (
            <Link href={LEARN_MORE_LINK} isExternal onClick={goLearnMore}>
              <MIFormattedText label="flows.addBankAccount.success.learnMoreLink" />
            </Link>
          ),
        }}
      />
    </Box>
  );

  useEffect(() => {
    analytics.page(eventPage, 'success', { flowEntryPoint });
  }, []);

  return (
    <SuccessLayoutPage
      illustration={IllustrationName.moneyBagCheck}
      textAlign="center"
      title="flows.addBankAccount.success.title"
      text="flows.addBankAccount.success.subtitle"
      buttonLabel="flows.addBankAccount.success.buttonCTA"
      buttonAction={saveAndContinue}
      hideHeader
      footer={Footer}
    />
  );
};
