import get from 'lodash/get';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { useOrgId } from 'src/hooks';
import { useUnifiedOnboardingFlag } from 'src/hooks/useUnifiedOnboardingFlag';
import { useNavigator } from 'src/modules/navigation/hooks/useNavigator';
import { profileStore } from 'src/modules/profile/profile-store';
import { isCompanyLegalInfoDone } from 'src/pages/settings/records';
import { getCompanyInfo } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { DeliveryMethodOrigin } from 'src/utils/consts';
import { useLocationState } from 'src/utils/hooks';
import { onboardingLocations } from '../locations';
import { VendorCompanyLogo } from './components/VendorCompanyLogo';
import { useVendorCompanyInfoUpdates } from './hooks/useVendorCompanyInfoUpdates';

const eventPage = 'onboarding-vendor-company-logo';

type Props = Parameters<typeof useVendorCompanyInfoUpdates>[0];

export const VendorCompanyLogoPageContainer = ({ companyInfoField, nextStepURL, prevStepURL, inputFields }: Props) => {
  const { navigate } = useNavigator();

  const orgId = useOrgId();
  const permissions = useSelector(profileStore.selectors.getPermissions);
  const isUnifiedOnboardingOpen = useUnifiedOnboardingFlag();
  const [origin] = useLocationState<DeliveryMethodOrigin>('origin');
  const userSettings = get(permissions, 'settings', false);
  const editable = userSettings ? userSettings.editCompanySettings() : false;
  const relativeStep = isUnifiedOnboardingOpen ? 9 / 15 : 6 / 10;
  const { onPrev, isLoading } = useVendorCompanyInfoUpdates({
    companyInfoField,
    nextStepURL,
    prevStepURL,
    inputFields,
  });

  const companyInfo = useSelector(getCompanyInfo);

  const goToNextStep = useCallback(() => {
    if (origin === DeliveryMethodOrigin.MSN_PORTAL_GENERIC_LINK_CREATION) {
      navigate(generatePath(onboardingLocations.vendorCompanyInfo.legal, { orgId }), false, { origin });
    } else if (isCompanyLegalInfoDone(companyInfo)) {
      navigate(generatePath(onboardingLocations.vendorCompanyInfo.businessType, { orgId }), false, {
        origin,
      });
    } else {
      navigate(generatePath(onboardingLocations.vendorCompanyInfo.legal, { orgId }), false, {
        origin,
      });
    }
  }, [navigate, orgId, origin, companyInfo]);

  const handleSkip = useCallback(() => {
    analytics.track(eventPage, 'skip');
    goToNextStep();
  }, [goToNextStep]);

  const handleNext = useCallback(() => {
    analytics.track(eventPage, 'continue');
    goToNextStep();
  }, [goToNextStep]);

  return (
    <VendorCompanyLogo
      onPrev={onPrev}
      onNext={handleNext}
      onSkip={handleSkip}
      isLoading={isLoading}
      editable={editable}
      relativeStep={relativeStep}
    />
  );
};
