import { featureFlags } from '@melio/shared-web';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useBreak } from 'src/hoc';
import { usePreservedStateNavigator } from 'src/modules/navigation/hooks/usePreservedStateNavigator';
import { useOrganizationPreferences } from 'src/modules/organizations/hooks/useOrganizationPreferences';
import { companiesLocations } from 'src/pages/companies/locations';
import { OperatingSystem } from 'src/pages/onboarding/company-info/consts';
import { getMobileOperatingSystem } from 'src/pages/onboarding/company-info/utils';
import { onboardingLocations } from 'src/pages/onboarding/locations';
import { useFetchCompanySettings } from 'src/pages/settings/hooks/useFetchCompanySettings';
import { getCompanyInfo, getUserPreferences } from 'src/redux/user/selectors';
import { analytics } from 'src/services/analytics';
import { CompanyFormPage, CompanyInfoOnboardingOrigin, CompanyType, FeatureFlags } from 'src/utils/consts';
import locations from 'src/utils/locations';
import { CompanyAddedPage } from './components/CompanyAddedPage';

export const CompanyAddedPageContainer = () => {
  const { navigate, navigateWithPreservedState } = usePreservedStateNavigator();
  const { state: locationState } = useLocation<Record<string, any>>();
  const origin = locationState?.origin ? locationState.origin : '';
  const invitingOrgId = locationState?.invitingOrgId ? locationState.invitingOrgId : '';
  const { allowCompaniesSwitcher } = useSelector(getUserPreferences);
  const organizationPreferences = useOrganizationPreferences();
  const isAccountantAddCompanyFlow =
    origin === CompanyInfoOnboardingOrigin.ACCOUNTANT_ADD_COMPANY || organizationPreferences.uninvitedOwnerEmail;
  const { companyName, companyType, id: orgId } = useSelector(getCompanyInfo);
  const isSMB = companyType === CompanyType.SMB;
  const { isMobile } = useBreak();
  const mobileOperatingSystem = isMobile && getMobileOperatingSystem();
  const [isAndroidAppPromotionEnabled] =
    mobileOperatingSystem === OperatingSystem.ANDROID
      ? featureFlags.useFeature(FeatureFlags.AndroidAppPromotionEnabled, false)
      : [false];
  const [isIosAppPromotionEnabled] =
    mobileOperatingSystem === OperatingSystem.IOS
      ? featureFlags.useFeature(FeatureFlags.IosAppPromotionEnabled, false)
      : [false];
  const showMobileAppPromotion = isAndroidAppPromotionEnabled || isIosAppPromotionEnabled;

  const { fetch: fetchCompanyInfo } = useFetchCompanySettings();
  useEffect(() => {
    analytics.page('onboarding-company-info', 'added');
  }, []);
  useEffect(() => {
    if (orgId) {
      fetchCompanyInfo();
    }
  }, [fetchCompanyInfo, orgId]);

  const goToDashboard = () => {
    analytics.track('onboarding-company-info', 'goToDashboard-Clicked');

    if (showMobileAppPromotion) {
      navigate(onboardingLocations.mobileApp.promotion);
    } else {
      navigate(locations.MainApp.dashboard.url(), false, {
        origin: CompanyFormPage.ONBOARDING,
      });
    }
  };

  const onNext = () => {
    if (navigateWithPreservedState) {
      navigateWithPreservedState();
    } else if (showMobileAppPromotion) {
      navigate(onboardingLocations.mobileApp.promotion);
    } else if ((allowCompaniesSwitcher && !isAccountantAddCompanyFlow) || invitingOrgId) {
      navigate(companiesLocations.index);
    } else {
      goToDashboard();
    }
  };

  const isComingFromPaymentRequest = [
    CompanyInfoOnboardingOrigin.PAYMENT_REQUEST,
    CompanyInfoOnboardingOrigin.GUEST_PAYMENT_REQUEST,
  ].includes(origin);

  if (navigateWithPreservedState && !isComingFromPaymentRequest) {
    navigateWithPreservedState();
  }

  return (
    <CompanyAddedPage
      onNext={onNext}
      origin={origin}
      orgId={orgId}
      goToDashboard={goToDashboard}
      isAccountantAddCompanyFlow={!!isAccountantAddCompanyFlow}
      companyName={companyName}
      isSMB={isSMB}
    />
  );
};
