import { useFeature } from '@melio/shared-web/dist/feature-flags';
import { getValidationErrors } from '@melio/sizzers-js-common';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { MICopyWithPreviewLink } from 'src/components/common/MICopyWithPreviewLink';
import { StepLayoutPage } from 'src/components/layout/StepLayoutPage';
import { useModal } from 'src/helpers/react/useModal';
import { useStoreActions } from 'src/helpers/redux/createRestfulSlice';
import invoicesStore from 'src/modules/invoices/invoices-store';
import useCustomer from 'src/pages/get-paid/hooks/useCustomer';
import { useFullPaymentRequestLink } from 'src/pages/get-paid/hooks/useFullPaymentRequestLink';
import { getOrgId, getOwnedVendorHandle } from 'src/redux/user/selectors';
import { CommonDialog } from 'src/ui/dialog/CommonDialog';
import { useForm } from 'src/ui/form';
import { CopyLinkSize, FeatureFlags } from 'src/utils/consts';
import { useQueryString } from 'src/utils/hooks';
import SendPaymentRequestForm, { SendPaymentRequestType } from './components/form/SendPaymentRequestForm';
import { PaymentRequestSentFrom } from './constants';
import { PageProps } from './CreateInvoiceRouter';
import { getPaymentRequestModel } from './utils';

export type Props = PageProps & {
  onGoToSelectedItem: (id: string) => void;
  getProEnabled?: boolean;
};

export const NewInvoiceSendPaymentRequestPage = ({ onExit, onGoToSelectedItem, getProEnabled }: Props) => {
  const [isAttachInvoiceFileFeatureOpen] = useFeature(FeatureFlags.AttachInvoiceFile, false);
  const invoicesActions = useStoreActions(invoicesStore);
  const orgId = useSelector(getOrgId);
  const ownedVendorHandle = useSelector(getOwnedVendorHandle);
  const query = useQueryString();
  const shareSelector = invoicesStore.selectors.share;
  const invoice = useSelector(invoicesStore.selectors.fetch.byId(query.id));
  const isLoading = useSelector(shareSelector.status(query.id));
  const [customer] = useCustomer(invoice?.customer?.id);
  const { MELIO_PRO, MELIO_ME } = PaymentRequestSentFrom;
  const fullPaymentRequestLink = useFullPaymentRequestLink({ ownedVendorHandle, invoice });
  const model = useMemo(() => getPaymentRequestModel(query.id, invoice, customer), [query.id, invoice, customer]);

  const onMarkAsSend = async (payload) => {
    await invoicesActions.markAsSend(payload);

    onGoToSelectedItem(query.id);
  };

  const onSubmit = async (paymentRequest: SendPaymentRequestType) => {
    const { customerEmail, customerNote, paymentRequestId, contactPhone, shouldAttachInvoice } = paymentRequest;
    const data = {
      paymentRequestId,
      customerEmail,
      contactPhone,
      isCopyEmailToUser: false,
      customerNote,
      sentFrom: getProEnabled ? MELIO_PRO : MELIO_ME,
      shouldAttachInvoice,
    };
    const payload = { orgId, id: query.id, data };
    await invoicesActions.share(payload);
    onGoToSelectedItem(query.id);
  };

  const [paymentRequestMV, { submit }] = useForm(model, {
    submit: onSubmit,
  });

  const onCopyEmailToUser = () => {
    const sentFrom = getProEnabled ? MELIO_PRO : MELIO_ME;
    const customerEmail = paymentRequestMV.customerEmail.value;
    const customerNote = paymentRequestMV.customerNote.value;
    const shouldUpdateCustomerEmail =
      customerEmail !== model.customerEmail &&
      isEmpty(getValidationErrors('paymentRequest', { customerEmail }, ['customerEmail']));

    const payload = {
      orgId,
      id: query.id,
      data: { sentFrom, customerNote, ...(shouldUpdateCustomerEmail && { customerEmail }) },
    };

    onMarkAsSend(payload);
  };

  const [ShareInvoiceLinkModal, onOpen] = useModal(CommonDialog, {
    title: 'getPaid.new.paymentRequest.modal.title',
    description: 'getPaid.new.paymentRequest.modal.subTitle',
    textValues: {
      link: fullPaymentRequestLink,
    },
    hideIcon: true,
    showCancel: false,
    maxWidth: '56rem',
    minHeight: '35rem',
    children: (
      <MICopyWithPreviewLink
        clipboardText={fullPaymentRequestLink}
        link={fullPaymentRequestLink}
        size={CopyLinkSize.BIG}
        buttonLabel="getPaid.new.paymentRequest.modal.copyAndCloseButton"
        linkLabel="getPaid.new.paymentRequest.modal.previewLinkText"
        onCopy={onCopyEmailToUser}
      />
    ),
  });

  useEffect(() => {
    if (query.id) {
      invoicesActions.fetch({ id: query.id, orgId, tab: 'requests' });
    }
  }, [invoicesActions, orgId, query.id]);

  return (
    <StepLayoutPage
      goExit={onExit}
      isPrevDisabled
      relativeStep={0}
      title="getPaid.new.paymentRequest.title"
      subtitle="getPaid.new.paymentRequest.subTitle"
      subTitleValues={{ customerName: paymentRequestMV.customerName.value }}
    >
      <SendPaymentRequestForm
        submit={submit}
        isLoading={isLoading}
        isDisabled={!paymentRequestMV.customerEmail.value}
        model={paymentRequestMV}
        onShareableLink={onOpen}
        enableInvoiceAttachment={isAttachInvoiceFileFeatureOpen && Boolean(invoice?.files?.length)}
      />
      {ShareInvoiceLinkModal}
    </StepLayoutPage>
  );
};
